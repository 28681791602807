import './PrideFlag.scss';

function PrideFlag() {
  return (
    <div className='PrideFlag'>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
  );
}

export default PrideFlag;
